// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";
@import "../../common.scss";

.root {
  @include font-defaults;
}

.input_icon {
  color: rgba(0,0,0,0.25);
}

.error_feedback {
  display: block;
  margin-bottom: 8px;
}

.login_button {
  width: 230px;
  margin-top: 16px;
  margin-bottom: 8px;
  
  span {
    font-weight: 500;
  }
}